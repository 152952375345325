import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-cb5db854"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src", "onClick"];
const _hoisted_2 = ["src", "onClick"];
const _hoisted_3 = {
  key: 2,
  class: "list4"
};
const _hoisted_4 = ["src", "onClick"];
const _hoisted_5 = {
  key: 3,
  class: "list6"
};
const _hoisted_6 = {
  class: "img-div"
};
const _hoisted_7 = ["src", "onClick"];
const _hoisted_8 = {
  class: "title"
};
const _hoisted_9 = {
  key: 4,
  class: "notice-div"
};
const _hoisted_10 = ["onClick"];
const _hoisted_11 = {
  key: 5,
  class: "ads-text"
};
const _hoisted_12 = {
  key: 6,
  class: "list12"
};
const _hoisted_13 = {
  key: 7,
  class: "list13"
};
const _hoisted_14 = {
  key: 8,
  class: "list14"
};
const _hoisted_15 = ["src", "onClick"];
import { ref } from 'vue';
export default {
  __name: 'AdsPictures',
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    },
    type: {
      default: 1
    }
  },
  setup(__props) {
    const clickImg = item => {
      window.open(item.url);
    };
    const show14 = ref(true);
    return (_ctx, _cache) => {
      const _component_van_swipe_item = _resolveComponent("van-swipe-item");
      const _component_van_swipe = _resolveComponent("van-swipe");
      const _component_van_text_ellipsis = _resolveComponent("van-text-ellipsis");
      const _component_van_tag = _resolveComponent("van-tag");
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock(_Fragment, null, [[1].includes(__props.type) ? (_openBlock(), _createBlock(_component_van_swipe, {
        key: 0,
        class: "my-swipe",
        autoplay: 3000,
        "indicator-color": "white"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item)
            }, null, 8, _hoisted_1)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })) : _createCommentVNode("", true), [3].includes(__props.type) ? (_openBlock(), _createBlock(_component_van_swipe, {
        key: 1,
        class: "my-swipe3",
        autoplay: 3000,
        "indicator-color": "white"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item)
            }, null, 8, _hoisted_2)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })) : _createCommentVNode("", true), __props.type === 4 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createElementVNode("img", {
          src: item.icon,
          alt: "",
          onClick: $event => clickImg(item)
        }, null, 8, _hoisted_4)]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 6 && __props.list.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createElementVNode("div", _hoisted_6, [_createElementVNode("img", {
          src: item.icon,
          alt: "",
          onClick: $event => clickImg(item)
        }, null, 8, _hoisted_7)]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_van_text_ellipsis, {
          content: item.name
        }, null, 8, ["content"])])]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 9 && __props.list.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          onClick: $event => clickImg(item)
        }, _toDisplayString(item.name), 9, _hoisted_10);
      }), 128))])])) : _createCommentVNode("", true), __props.type === 11 ? (_openBlock(), _createElementBlock("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
        return _openBlock(), _createBlock(_component_van_tag, {
          key: index,
          type: "primary",
          color: "#cce5ff",
          "text-color": "#004085",
          onClick: $event => clickImg(item)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 12 ? (_openBlock(), _createElementBlock("div", _hoisted_12, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
        return _openBlock(), _createBlock(_component_van_tag, {
          key: index,
          type: "primary",
          color: "#222",
          "text-color": "#c6c6c6",
          onClick: $event => clickImg(item)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 13 ? (_openBlock(), _createElementBlock("div", _hoisted_13, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
        return _openBlock(), _createBlock(_component_van_tag, {
          key: index,
          type: "primary",
          color: "#222",
          "text-color": "#c6c6c6",
          onClick: $event => clickImg(item)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 14 ? (_openBlock(), _createElementBlock("div", _hoisted_14, [show14.value ? (_openBlock(), _createBlock(_component_van_swipe, {
        key: 0,
        class: "my-swipe14",
        autoplay: 3000,
        "indicator-color": "white"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item)
            }, null, 8, _hoisted_15), _createVNode(_component_van_icon, {
              name: "cross",
              class: "popup-close-icon",
              onClick: _cache[0] || (_cache[0] = $event => show14.value = false)
            })]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })) : _createCommentVNode("", true)])) : _createCommentVNode("", true)], 64);
    };
  }
};