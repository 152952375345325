import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6b506ebc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "video-js vjs-default-skin vjs-big-play-centered",
  id: "my-player"
};
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { onBeforeUnmount, computed, watchEffect, ref } from "vue";
export default {
  __name: 'VideoPlay',
  props: ["info"],
  setup(__props) {
    const props = __props;
    const info = computed(() => props.info);
    const player = ref({});
    watchEffect(() => {
      if (info.value.href) {
        // 数据加载后执行某些操作...
        initPlayer();
      }
    });

    // 初始化播放器
    const initPlayer = () => {
      player.value = videojs("my-player", {
        // poster: info.value.upload_thumb,
        width: "100%",
        controls: true,
        // 显示播放的控件
        // fluid: true, // 自适应宽高
        playsinline: true,
        webkitPlaysinline: true,
        // 导致视频一结束就重新开始。
        loop: false,
        muted: false,
        preload: "auto",
        sources: [
        // 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
        {
          src: info.value.href || require("@/assets/video/1.mp4")
        }
        // 视频宽度,获取客户端宽度
        ]
      }, function onPlayerReady() {
        videojs.log("播放器准备好了!");

        // In this context, `this` is the player that was created by Video.js.
        // this.play();

        this.on("ended", function () {
          videojs.log("播放结束!");
        });
      });
    };
    onBeforeUnmount(() => {
      if (player.value.dispose) {
        player.value.dispose();
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("video", _hoisted_1);
    };
  }
};