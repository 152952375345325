import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import install from "./utils/install";

const app = createApp(App);

import { Search } from "vant";
app.use(Search);

app.config.globalProperties.$isDev = process.env.NODE_ENV === "development";
app.config.globalProperties.$isDev = false;

app.use(install);
app.use(router);
app.mount("#app");
