import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
import { onMounted } from "vue";
import { getCurrentInstance, watch, ref } from "vue";
import { useRoute } from "vue-router";
import api from "@/api/shipin2/index.js";
export default {
  __name: 'CategoryNav',
  props: {
    list: {}
  },
  emits: ['query'],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const route = useRoute();
    const isDev = getCurrentInstance()?.appContext.config.globalProperties.$isDev;
    const jump = url => {
      window.location.href = url;
    };
    const active = ref();
    const change = ({
      name
    }) => {
      if (props.list[name].url) {
        window.location.href = props.list[name].url;
      } else {
        emit('query', props.list[name]);
      }
    };
    watch(() => props.list, () => {
      if (route.name === 'home') {
        active.value = props.list.findIndex(v => v.title === route.query.title);
      }
    });
    const cateList = ref([]);

    // 查询分类列表
    const getCateList = async () => {
      const res = await api.getCateList();
      cateList.value = res.data;
    };
    onMounted(() => {
      getCateList();
    });
    return (_ctx, _cache) => {
      const _component_van_tab = _resolveComponent("van-tab");
      const _component_van_tabs = _resolveComponent("van-tabs");
      return _openBlock(), _createBlock(_component_van_tabs, {
        class: "tabs",
        active: active.value,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => active.value = $event),
        sticky: "",
        "line-height": "0",
        onClickTab: change
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
          return _openBlock(), _createBlock(_component_van_tab, {
            name: index,
            key: index,
            title: item.title
          }, null, 8, ["name", "title"]);
        }), 128))]),
        _: 1
      }, 8, ["active"]);
    };
  }
};