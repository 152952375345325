import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-85ce05fa"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  class: "img-div"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "img-div"
};
const _hoisted_6 = {
  class: "mask"
};
const _hoisted_7 = {
  class: "title"
};
const _hoisted_8 = {
  class: "mask-div"
};
const _hoisted_9 = {
  class: "watch-times"
};
const _hoisted_10 = {
  class: "title"
};
import { getCurrentInstance } from "vue";
export default {
  __name: 'VideoList',
  props: {
    list: {}
  },
  setup(__props) {
    const isDev = getCurrentInstance()?.appContext.config.globalProperties.$isDev;
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["video-list", _ctx.$route.query.onlytTopic === '1' && 'onlytTopic'])
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: index
        }, [item.webIsAds ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_image, {
          class: "img",
          fit: "cover",
          src: item.icon
        }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_3, _toDisplayString(item.name), 1)])) : (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: $event => _ctx.$emit('query', item)
        }, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_van_image, {
          class: "img",
          fit: "cover",
          src: _unref(isDev) ? require('@/assets/images/shipin1/logo.png') : item.upload_thumb
        }, null, 8, ["src"]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(_unref(isDev) ? "标题" : item.title), 1), _createElementVNode("div", null, [_createTextVNode("瓜农 · "), _createElementVNode("span", null, _toDisplayString(item.create_date), 1), _createTextVNode(" · 今日吃瓜，人人吃瓜")])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_van_icon, {
          name: "eye-o"
        }), _createTextVNode(" " + _toDisplayString(item.watchtimes), 1)])])]), _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(isDev) ? "标题" : item.title), 1)], 8, _hoisted_4))], 64);
      }), 128))], 2);
    };
  }
};