import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0ffff47b"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tags-div"
};
import { useRouter } from 'vue-router';
export default {
  __name: 'VideoTags',
  setup(__props) {
    const router = useRouter();
    const list = ['自慰', '学生', '极品', '内射', '少女', '人妻', '萝莉', '巨乳', '乱伦', '高潮', '少妇', '女神', '黑丝', '调教', '无套', '真实', '美女', '中出', '大奶', '按摩', '白虎', '颜值', '诱惑', '大小', '淫荡', '老师', '口交'];
    const query = item => {
      router.push({
        path: "more",
        query: {
          keyword: item
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_tag = _resolveComponent("van-tag");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(list, (item, index) => {
        return _createVNode(_component_van_tag, {
          key: index,
          type: "primary",
          color: "#2a2a2a",
          onClick: $event => query(item)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item), 1)]),
          _: 2
        }, 1032, ["onClick"]);
      }), 64))]);
    };
  }
};