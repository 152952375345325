import request from "@/projects/shipin2/utils/request";

export default {
  // 查询首页视频
  queryHomeVideo: (params) => {
    return request({
      url: "/api/video/home",
      params,
    });
  },

  // 查询视频分类列表
  queryClassificationList: (params) => {
    return request({
      url: "/api/video/nav",
      params,
    });
  },

  // 查询视频分类列表
  queryVideoClassificationList: (params) => {
    return request({
      url: "/api/video/getList",
      params,
    });
  },

  // 查询视频详情
  queryVideoDetails: (params) => {
    return request({
      url: "/api/video/detail",
      params,
    });
  },

  // 查询公告
  queryAnnouncement: (params) => {
    return request({
      url: "/api/index/notice",
      params,
    });
  },

  // 查询广告图
  // type =1 表示banner图  =2 表示首页广告  =3 表示详情页广告
  queryAd: (params) => {
    return request({
      url: "/api/index/images",
      params,
    });
  },

  // 查询网站配置
  queryWebsiteConfiguration: (params) => {
    return request({
      url: "/api/index/config",
      params,
    });
  },

  // 查询推荐列表
  queryRecommendedList: (params) => {
    return request({
      url: "/api/video/getRelateList",
      params,
    });
  },

  // 搜索
  search: (params) => {
    return request({
      url: "/api/video/search",
      params,
    });
  },

  // 查询分类列表
  getCateList: (params) => {
    return request({
      url: "api/video/getCateList",
      params: {
        ...params,
      },
    });
  },
};
